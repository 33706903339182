import * as React from 'react'
import type { GatsbyBrowser } from 'gatsby'
import Layout from './src/components/layout'
import { Providers } from './src/components/providers'
import NoPermissionsPage from './src/components/NoPermissionsPage'
import ReactDOM from 'react-dom/client'
import '@mantine/core/styles.css'
import '@mantine/notifications/styles.css'
import '@mantine/charts/styles.css'
import 'mantine-react-table/styles.css'
import './src/styles/global.css'
import 'react-pdf/dist/Page/TextLayer.css'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import { useIsMember } from './src/hooks/useIsMember'
import { LoadingOverlay } from '@mantine/core'
import { useAuth0 } from '@auth0/auth0-react'
import posthog from 'posthog-js'

if (process.env.GATSBY_POSTHOG_KEY) {
  posthog.init(process.env.GATSBY_POSTHOG_KEY, {
    api_host: `https://eu.i.posthog.com`,
    person_profiles: `identified_only`, // or 'always' to create profiles for anonymous users as well
  })
  console.log({
    key: process.env.GATSBY_POSTHOG_KEY,
    api_host: `https://eu.i.posthog.com`,
    person_profiles: `identified_only`, // or 'always' to create profiles for anonymous users as well
  })
} else {
  console.warn(`No PostHog key provided.`)
}

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => {
  return <Providers>{element}</Providers>
}

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}) => {
  const { logout, isAuthenticated, isLoading } = useAuth0()
  const isMember = useIsMember()

  if (isLoading) {
    return <LoadingOverlay visible={isLoading} />
  } else if (isAuthenticated && !isMember) {
    return <NoPermissionsPage />
  } else if (!isAuthenticated) {
    logout({
      logoutParams: {
        returnTo: process.env.GATSBY_AUTH0_CALLBACK,
      },
    })
    return
  } else {
    return <Layout {...props}>{element}</Layout>
  }
}

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}
